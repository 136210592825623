import { render, staticRenderFns } from "./default.vue?vue&type=template&id=69c515d9&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=69c515d9&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/vercel/path0/src/components/base/button.js').default,BaseModal: require('/vercel/path0/src/components/base/modal.vue').default})
