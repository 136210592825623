
import Vue from 'vue';

export default {
  name: 'default-layout',
  data () {
    return {
      is18: false,
      isModal: true,
    };
  },
  watch: {
    is18: {
      handler(value) {
        if (value && process.browser) this.$nuxt.$emit('start');
      },
      immediate: true,
    },
    '$route' () {
      Vue.prototype.$navigated = true;
    },
  },
  mounted() {
    /* this.is18 = this.get();
    if (!this.is18) this.isModal = true; */
  },
  methods: {
    save(value) {
      this.$cookiz.set('is18', value);
    },
    get() {
      return this.$cookiz.get('is18');
    },
    yes() {
      this.isModal = false;
      this.is18 = true;
      this.save(true);
    },
    no () {
      this.isModal = false;
      this.save(false);
    },
  },
};
