
import { lockScroll } from '~/mixins/lockScroll';
import IconX from '../icon/icon-x';

export default {
  name: 'base-modal',
  components: {
    IconX,
  },
  mixins: [lockScroll],
  props: {
    closeTitle: {
      type: String,
      default: 'Закрыть',
    },
    withLock: {
      type: Boolean,
      default: true,
    },
    withClose: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    window.addEventListener('keydown', this.closeWindowEsc);
  },
  destroyed() {
    window.removeEventListener('keydown', this.closeWindowEsc);
  },
  methods: {
    closeWindow() {
      this.$emit('close');
    },
    closeWindowEsc(e) {
      if (e.key === 'Escape') this.closeWindow();
    },
  },
};
